<template>
    <section id="dashboard-fiordo-global">

        <b-tabs v-model="tabIndex">

            <b-tab title="Dashboard Global Calbuco">
                <global-calbuco-tab-vue></global-calbuco-tab-vue>
            </b-tab>

            <b-tab title="Dashboard Global Coronel">
                <global-coronel-tab-vue></global-coronel-tab-vue>
            </b-tab>

        </b-tabs>        
        
        <b-row>
          <b-col>
            <b-card>
            <b-button
            variant="primary"
            class="float-right"
            @click="goToBack"
            size="lg"
            >
            Ir al Histórico de Planificaciones
            </b-button>
            </b-card>
          </b-col>
        </b-row>

    </section>
</template>
<script>
import GlobalCalbucoTabVue from "./GlobalCalbucoTab.vue";
import GlobalCoronelTabVue from "./GlobalCoronelTab.vue";
import{BRow, BCol, BButton } from "bootstrap-vue";
import router from '@/router';
export default {

    mounted()
    {
      
    },

    data()
    {
        let activeTabName = this.$route.params.active_tab_name;
        let tabIndex = 0;

        if (activeTabName == "Carga de Archivos"){
            tabIndex = 0
        }

        if (activeTabName == "Histórico de Carga"){
            tabIndex = 1
        }

        return {
           tabIndex: tabIndex 
        };
    },
    components: {
        BRow,
        BCol,
        BButton,
        GlobalCalbucoTabVue,
        GlobalCoronelTabVue
    },

    methods: {
      goToBack(){
        router.push({ name: 'dashboard-fiordo-historico-planificacion' })
      }
    },
};
</script>
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>