<template>
    <section id="dashboard-fiordo-global-Coronel">
        Costos y Valorización Blending para Contratos Coronel
        <hr class="solid">
        <b-card>
            <div class="card-header">
                <!-- Title & SubTitle -->
                <h5 class="font-weight-bolder">
                    Estas viendo la ultima planificación, hecha el 23/06/2022 realizada por Juanito Perez
                </h5>

                <!-- Card Actions -->
                <div class="heading-elements">
                    <ul class="list-inline mb-0">
                    <li>
                        <feather-icon
                        icon="HelpCircleIcon"
                        size="20"
                        />
                    </li>
                    </ul>
                </div>
            </div>
        </b-card>

        <b-row>
            <b-col cols="3" v-for="(item, index) in itemsData1" :key="index">
                <b-card class="text-center">
                  
                  <div class="heading-elements">
                    <ul class="list-inline col text-right">
                      <li>
                        <feather-icon
                          icon="HelpCircleIcon"
                          
                          size="20"
                        />
                      </li>
                    </ul>
                  </div>

                  <b-avatar class="mb-1" :variant="item.color" size="45">
                    <feather-icon size="21" :icon="item.icon" />
                  </b-avatar>
                  <div class="truncate">
                    <h5 class="mb-25 font-weight-bolder">{{ item.title }}</h5>
                    <span>{{ item.subtitle }}</span>
                  </div>
                </b-card>
            </b-col>
        </b-row>

        Calidad y Rendimiento Coronel
        <hr class="solid">
        <b-row>
            <b-col cols="3" v-for="(item, index) in itemsData2" :key="index">
                <b-card class="text-center">
                  
                  <div class="heading-elements">
                    <ul class="list-inline col text-right">
                      <li>
                        <feather-icon
                          icon="HelpCircleIcon"
                          
                          size="20"
                        />
                      </li>
                    </ul>
                  </div>

                  <b-avatar class="mb-1" :variant="item.color" size="45">
                    <feather-icon size="21" :icon="item.icon" />
                  </b-avatar>
                  <div class="truncate">
                    <h5 class="mb-25 font-weight-bolder">{{ item.title }}</h5>
                    <span>{{ item.subtitle }}</span>
                  </div>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
          <b-col>
            <bruna-element-loader :dataUrl="chart0"></bruna-element-loader>
          </b-col>
        </b-row>

        Contratos Coronel
        <hr class="solid">
        <b-row>
            <b-col cols="6" v-for="(item, index) in itemsData3" :key="index">
                <b-card class="text-center">
                  
                  <div class="heading-elements">
                    <ul class="list-inline col text-right">
                      <li>
                        <feather-icon
                          icon="HelpCircleIcon"
                          
                          size="20"
                        />
                      </li>
                    </ul>
                  </div>

                  <b-avatar class="mb-1" :variant="item.color" size="45">
                    <feather-icon size="21" :icon="item.icon" />
                  </b-avatar>
                  <div class="truncate">
                    <h5 class="mb-25 font-weight-bolder">{{ item.title }}</h5>
                    <span>{{ item.subtitle }}</span>
                  </div>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
              <bruna-element-loader :dataUrl="chart1"></bruna-element-loader>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
              <bruna-element-loader :dataUrl="chart2"></bruna-element-loader>
            </b-col>
        </b-row>

        Costos y Valorización Blending Potencial Stock
        <hr class="solid">
        <b-row>
            <b-col cols="3" v-for="(item, index) in itemsData4" :key="index">
                <b-card class="text-center">
                  
                  <div class="heading-elements">
                    <ul class="list-inline col text-right">
                      <li>
                        <feather-icon
                          icon="HelpCircleIcon"
                          
                          size="20"
                        />
                      </li>
                    </ul>
                  </div>

                  <b-avatar class="mb-1" :variant="item.color" size="45">
                    <feather-icon size="21" :icon="item.icon" />
                  </b-avatar>
                  <div class="truncate">
                    <h5 class="mb-25 font-weight-bolder">{{ item.title }}</h5>
                    <span>{{ item.subtitle }}</span>
                  </div>
                </b-card>
            </b-col>
        </b-row>

    </section>
</template>
<script>
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import{BRow, BCol, BButton } from "bootstrap-vue";
import useApiServices from '@/services/useApiServices.js';
import router from '@/router';
import axios from "@axios";
export default {

    mounted()
    {
      axios.get(useApiServices.globalCoronelStatistics1)
      .then((response) =>
      {
          this.itemsData1 = response.data;
      })
      .catch((error) =>
      {

      });

      axios.get(useApiServices.globalCoronelStatistics2)
      .then((response) =>
      {
          this.itemsData2 = response.data;
      })
      .catch((error) =>
      {

      });

      axios.get(useApiServices.globalCoronelStatistics3)
      .then((response) =>
      {
          this.itemsData3 = response.data;
      })
      .catch((error) =>
      {

      });

      axios.get(useApiServices.globalCoronelStatistics4)
      .then((response) =>
      {
          this.itemsData4 = response.data;
      })
      .catch((error) =>
      {

      });
    },

    data()
    {
        return {
            chart0: useApiServices.globalCoronelChart0 + "?centro=all",
            chart1: useApiServices.globalCoronelChart1 + "?centro=all",
            chart2: useApiServices.globalCoronelChart2 + "?centro=all",
            itemsData1: [],
            itemsData2: [],
            itemsData3: [],
            itemsData4: []
        };
    },
    components: {
        BRow,
        BCol,
        BButton,
        BrunaElementLoader
    },

    methods: {
      goToBack(){
        router.push({ name: 'dashboard-fiordo-historico-planificacion' })
      }
    },
};
</script>